html, body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  color: #000000;
  font-size: 12px; }
  @media (min-width: 425px) {
    html, body {
      font-size: 12px; } }
  @media (min-width: 768px) {
    html, body {
      font-size: 14px; } }
  @media (min-width: 1200px) {
    html, body {
      font-size: 16px; } }

main {
  padding-top: 60px; }

.animated.delay-0-3s {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

.animated.delay-0-5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s; }

.animated.delay-0-7s {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s; }

.animated.delay-0-9s {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s; }

.animated.delay-1-1s {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s; }

.animated.delay-1-3s {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s; }

.animated.delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s; }

.animated.delay-2-5s {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s; }

.animated.delay-3-5s {
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s; }

.animated.delay-4-5s {
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s; }

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; } }

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; } }

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; } }

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; } }

@media (min-width: 1400px) {
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-100 {
    width: 100% !important; } }

p {
  margin: 0; }

.title {
  font-weight: 900;
  font-size: 2rem;
  line-height: 1; }
  @media (min-width: 768px) {
    .title {
      font-size: 2.2rem; } }
  @media (min-width: 1200px) {
    .title {
      font-size: 2.5rem; } }

.subtitle {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1; }

.title-case {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1; }

.text {
  font-weight: 500;
  font-size: 1rem; }

.newsletter {
  padding: 5rem 0;
  background-color: #f5f5f5; }
  .newsletter form input[type="email"] {
    border: 1px solid #000000;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px; }
    .newsletter form input[type="email"]:focus {
      outline: none;
      box-shadow: none; }

.bg-purple {
  background-color: #8345FF; }

.bg-red {
  background-color: #FF1D3C; }

.bg-yellow {
  background-color: #FFAD5E; }

.bg-blue {
  background-color: #38D3FF; }

.text-purple {
  color: #8345FF; }

.text-red {
  color: #FF1D3C; }

.text-yellow {
  color: #FFAD5E; }

.text-blue {
  color: #38D3FF; }

.border-purple:focus {
  border: 1px solid #8345FF !important; }

.border-red:focus {
  border: 1px solid #FF1D3C !important; }

.border-yellow:focus {
  border: 1px solid #FFAD5E !important; }

.border-blue:focus {
  border: 1px solid #38D3FF !important; }

#navbar {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: #242121;
  z-index: 3050; }
  #navbar .container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  #navbar .logo-navbar {
    height: 30px; }
  #navbar .btn-menu {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%); }
    #navbar .btn-menu svg rect {
      transition: all 0.3s; }
    #navbar .btn-menu.disabled {
      opacity: 1; }
    #navbar .btn-menu:hover svg rect {
      fill: #FFAD5E; }
    #navbar .btn-menu:focus {
      outline: none;
      box-shadow: none; }

#content-menu {
  position: fixed;
  background-color: #242121;
  width: 100%;
  height: calc(100vh - 60px);
  top: 60px;
  left: 0;
  z-index: 3050; }
  #content-menu .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; }
    #content-menu .container ul {
      list-style: none;
      text-align: center;
      padding: 0; }
    #content-menu .container .menu {
      overflow-y: hidden; }
      #content-menu .container .menu ul li {
        padding: 1rem 0; }
        #content-menu .container .menu ul li a {
          color: #ffffff;
          font-size: 2.4rem;
          font-weight: 900;
          text-decoration: none;
          transition: all 0.3s; }
          #content-menu .container .menu ul li a:hover, #content-menu .container .menu ul li a.active {
            color: #FFAD5E; }
    #content-menu .container .rrss p {
      color: #ffffff;
      text-align: center;
      font-size: 1.2rem; }
    #content-menu .container .rrss a svg path {
      transition: all 0.3s; }
    #content-menu .container .rrss a:hover svg path {
      fill: #FFAD5E; }

/* -------------------------------------- */
/* BOTONES REDES SOCIALES */
/* -------------------------------------- */
.rrss a img {
  height: 25px; }

.rs {
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center; }

.rs:before {
  border-radius: 3px;
  width: 35px;
  height: 35px;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleY(0);
  transform-origin: 50% 100%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out; }

.rs.fb:before {
  background-color: #3b5998; }

.rs.ig:before {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #bc1888 75%, #833ab4 100%) !important; }

.rs.tw:before {
  background-color: #33a9ee; }

.rs.yt:before {
  background-color: #f00; }

.rs.sf:before {
  background-color: #1DB954; }

.rs.fb:hover:before,
.rs.ig:hover:before,
.rs.yt:hover:before,
.rs.sf:hover:before,
.rs.tw:hover:before {
  transform: scaleY(1); }

/* -------------------------------------- */
/* BOTONES */
/* -------------------------------------- */
.btn-rounded {
  border-radius: 50px;
  line-height: 1.75; }

.btn-custom {
  font-size: .8rem; }

.btn-black {
  padding: 0.6rem 2rem;
  color: #ffffff;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 50px;
  font-weight: bold;
  transition: all 0.3s; }

.btn-black:hover {
  color: #000000; }

.btn-black:before {
  background: #ffffff; }

.btn-black:focus {
  box-shadow: none;
  color: #ffffff; }

.btn-black:focus:hover {
  box-shadow: none;
  color: #000000; }

.btn-center-out {
  transform: perspective(1px) translateZ(0);
  overflow: hidden; }

.btn-center-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.3s; }

.btn-center-out:hover:before,
.btn-center-out:active:before {
  transform: scale(1.15); }

.btn-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden; }

.btn-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  height: 2px;
  background-color: #FFAD5E;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out; }

.btn-underline-from-center:hover:before,
.btn-underline-from-center.active:before {
  left: 0;
  right: 0; }

footer {
  padding: 5rem 0 0 0;
  color: #ffffff; }
  footer .footer1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    footer .footer1 .row {
      padding: 1rem 0; }
      @media (min-width: 768px) {
        footer .footer1 .row {
          padding: 5rem 0; } }
      footer .footer1 .row > div {
        margin-top: 1rem; }
        @media (min-width: 768px) {
          footer .footer1 .row > div {
            margin-top: 0; } }
      footer .footer1 .row a {
        color: #ffffff;
        font-weight: 700;
        text-decoration: none;
        transition: all 0.3s; }
        footer .footer1 .row a:hover {
          color: #000000; }
      footer .footer1 .row .list {
        list-style: none;
        padding: 0;
        margin: 0; }
        footer .footer1 .row .list a svg path {
          transition: all 0.3s; }
        footer .footer1 .row .list a:hover svg path {
          fill: #000000; }
