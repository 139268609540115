/* -------------------------------------- */
/* BOTONES REDES SOCIALES */
/* -------------------------------------- */
.rrss a img {
  height: 25px;
}
.rs {
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rs:before {
  border-radius: 3px;
  width: 35px;
  height: 35px;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleY(0);
  transform-origin: 50% 100%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.rs.fb:before {
  background-color: #3b5998;
}
.rs.ig:before {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #bc1888 75%, #833ab4 100%) !important;
}
.rs.tw:before {
  background-color: #33a9ee;
}
.rs.yt:before {
  background-color: #f00;
}
.rs.sf:before {
  background-color: #1DB954;
}
.rs.fb:hover:before,
.rs.ig:hover:before,
.rs.yt:hover:before,
.rs.sf:hover:before,
.rs.tw:hover:before {
  transform: scaleY(1);
}
/* -------------------------------------- */
/* BOTONES */
/* -------------------------------------- */
.btn-rounded {
  border-radius: 50px;
  line-height: 1.75;
}
.btn-custom {
  font-size: .8rem;
}
.btn-black {
  padding: 0.6rem 2rem;
  color: $white;
  background: $black;
  border: 1px solid $black;
  border-radius: 50px;
  font-weight: bold;
  transition: all 0.3s;
}
.btn-black:hover {
  color: $black;
}
.btn-black:before {
  background: $white;
}
.btn-black:focus {
  box-shadow: none;
  color: $white;
}
.btn-black:focus:hover {
  box-shadow: none;
  color: $black;
}
.btn-center-out {
  transform: perspective(1px) translateZ(0);
  overflow: hidden;
}
.btn-center-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.3s;
}
.btn-center-out:hover:before,
.btn-center-out:active:before {
  transform: scale(1.15);
}
.btn-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.btn-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  height: 2px;
  background-color: $yellow;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.btn-underline-from-center:hover:before,
.btn-underline-from-center.active:before {
  left: 0;
  right: 0;
}