html, body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  color: $black;
  font-size: 12px;
  @media (min-width: 425px) {
    font-size: 12px;
  }
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
}
main {
  padding-top: 60px;
}
.animated.delay-0-3s{-webkit-animation-delay:0.3s;animation-delay:0.3s}
.animated.delay-0-5s{-webkit-animation-delay:0.5s;animation-delay:0.5s}
.animated.delay-0-7s{-webkit-animation-delay:0.7s;animation-delay:0.7s}
.animated.delay-0-9s{-webkit-animation-delay:0.9s;animation-delay:0.9s}
.animated.delay-1-1s{-webkit-animation-delay:1.1s;animation-delay:1.1s}
.animated.delay-1-3s{-webkit-animation-delay:1.3s;animation-delay:1.3s}
.animated.delay-1-5s{-webkit-animation-delay:1.5s;animation-delay:1.5s}
.animated.delay-2-5s{-webkit-animation-delay:2.5s;animation-delay:2.5s}
.animated.delay-3-5s{-webkit-animation-delay:3.5s;animation-delay:3.5s}
.animated.delay-4-5s{-webkit-animation-delay:4.5s;animation-delay:4.5s}
@media (min-width: 576px) {
  .w-sm-25 {width: 25% !important;}
  .w-sm-50 {width: 50% !important;}
  .w-sm-75 {width: 75% !important;}
  .w-sm-100 {width: 100% !important;}
}
@media (min-width: 768px) {
  .w-md-25 {width: 25% !important;}
  .w-md-50 {width: 50% !important;}
  .w-md-75 {width: 75% !important;}
  .w-md-100 {width: 100% !important;}
}
@media (min-width: 992px) {
  .w-lg-25 {width: 25% !important;}
  .w-lg-50 {width: 50% !important;}
  .w-lg-75 {width: 75% !important;}
  .w-lg-100 {width: 100% !important;}
}
@media (min-width: 1200px) {
  .w-xl-25 {width: 25% !important;}
  .w-xl-50 {width: 50% !important;}
  .w-xl-75 {width: 75% !important;}
  .w-xl-100 {width: 100% !important;}
}
@media (min-width: 1400px) {
  .w-xxl-25 {width: 25% !important;}
  .w-xxl-50 {width: 50% !important;}
  .w-xxl-75 {width: 75% !important;}
  .w-xxl-100 {width: 100% !important;}
}
p {
  margin: 0;
}
.title {
  font-weight: 900;
  font-size: 2rem;
  line-height: 1;
  @media (min-width: 768px) {
    font-size: 2.2rem;
  }
  @media (min-width: 1200px) {
    font-size: 2.5rem;
  }
}
.subtitle {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1;
}
.title-case {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1;
}
.text {
  font-weight: 500;
  font-size: 1rem;
}
.newsletter {
  padding: 5rem 0;
  background-color: #f5f5f5;
  form {
    input[type="email"] {
      border: 1px solid $black;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
.bg-purple {
  background-color: $purple;
}
.bg-red {
  background-color: $red;
}
.bg-yellow {
  background-color: $yellow;
}
.bg-blue {
  background-color: $blue;
}
.text-purple {
  color: $purple;
}
.text-red {
  color: $red;
}
.text-yellow {
  color: $yellow;
}
.text-blue {
  color: $blue;
}
.border-purple:focus {
  border: 1px solid $purple !important;
}
.border-red:focus {
  border: 1px solid $red !important;
}
.border-yellow:focus {
  border: 1px solid $yellow !important;
}
.border-blue:focus {
  border: 1px solid $blue !important;
}