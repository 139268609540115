footer {
  padding: 5rem 0 0 0;
  color: $white;
  .footer1 {
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.5);
    .row {
      padding: 1rem 0;
      @media (min-width: 768px) {
        padding: 5rem 0;
      }
      & > div {
        margin-top: 1rem;
        @media (min-width: 768px) {
          margin-top: 0;
        }
      }
      a {
        color: $white;
        font-weight: 700;
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
          color: $black;
        }
      }
      .list {
        list-style: none;
        padding: 0;
        margin: 0;
        a {
          svg {
            path {
              transition: all 0.3s;
            }
          }
          &:hover {
            svg {
              path {
                fill: $black;
              }
            }
          }
        }
      }
    }
  }
}