#navbar {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: $black-nav;
  z-index: 3050;
  .container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo-navbar {
    height: 30px;
  }
  .btn-menu {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    svg rect {
      transition: all 0.3s;
    }
    &.disabled {
      opacity: 1;
    }
    &:hover {
      svg rect {
        fill: $yellow;
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
#content-menu {
  position: fixed;
  background-color: $black-nav;
  width: 100%;
  height: calc(100vh - 60px);
  top: 60px;
  left: 0;
  z-index: 3050;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    ul {
      list-style: none;
      text-align: center;
      padding: 0;
    }
    .menu {
      overflow-y: hidden;
      ul {
        li {
          padding: 1rem 0;
          a {
            color: $white;
            font-size: 2.4rem;
            font-weight: 900;
            text-decoration: none;
            transition: all 0.3s;
            &:hover,
            &.active {
              color: $yellow;
            }
          }
        }
      }
    }
    .rrss {
      p {
        color: $white;
        text-align: center;
        font-size: 1.2rem;
      }
      a {
        svg {
          path {
            transition: all 0.3s;
          }
        }
        &:hover {
          svg {
            path {
              fill: $yellow;
            }
          }
        }
      }
    }
  }
}